<template>
    <div>   
        <CForm ref="form" class="needs-validation"   >
            <CRow>
                <CCol> 
                    <CSelect
                        label="Ürün: "
                        placeholder="Seçiniz.."
                        :options="productionOrderPackings"
                        :value.sync="data.productionPackingId"
                        horizontal>
                    </CSelect>
                </CCol>
                <CCol> 
                    <CInput
                        label="Adet: "
                        horizontal
                        placeholder="0"
                        type="number"
                        v-model="data.invoiceAmount"
                    >
                    </CInput>
                </CCol>
            </CRow>
           
           
            </CForm>
           
            <CDataTable
              :items="productionInvoiceDetails"
              :fields="fields"
              :items-per-page="10"
              :active-page="1"
              pagination
              column-filter
              hover
              class="modalTable"
            >
              <template #delete="{item}">
                <td>
                  <span style="color: red; font-weight: bold; cursor: pointer;"
                        @click="openModalEvent('destroy',item, 'Sil', item.productionPacking+' isimli ürünü faturadan silmek üzeresiniz. Bu işlemi yapmak istediğinize emin misiniz?')">Sil</span>
                </td>
              </template>
            </CDataTable>
            <ConfirmModal
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :confirm="removeItem"
            :desc="modalDesc"
            :data="modalProps"
            :actionType="actionType"
            />
    </div>
</template>

<script>
    import ConfirmModal from '../../components/confirmModal'
   export default{
       name: "PackingForm",
       props:{
            additionalEvent: Function,
            params: Object,
            actionType: String,
       },
       components:{ ConfirmModal},
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create'){
                   this.data = {};
                   this.disabledAreas = false;
               }else{
                this.disabledAreas = true;
               }
           },
       },
       computed:{
        productionInvoiceDetails: function() {
            return this.$store.getters.productionInvoiceDetails
        },
        productionOrderPackings: function() {
            let data = ['Ürün Seç'];
            this.$store.getters.productionOrderPackings.map(po => data.push({label: po.name, value: po.id}))
            return data
        },
       
        
       },
       data: function(){
           return{
                data: this.params ? {...this.params} : {},
                modalTitle: '',
                modalProps: {},
                modalDesc: '',
                openModal: false,
                disabledAreas: this.actionType !== 'create',
                fields: [
                        {key: 'productionInvoice', label: 'Fatura No', _style: 'font-size:12px'},
                        {key: 'productionPacking', label: 'Açıklama', _style: 'font-size:12px'},
                        {key: 'invoiceAmount', label: 'Adet', _style: 'font-size:12px'},
                        {key: 'delete', label: 'Sil', _style: 'font-size:12px'},
                    ],
                    genderList: ["ERKEK", "KADIN", "UNISEX"],
                module: 'notification',
                form: '',
           }
       },
       methods: {
        openModalEvent(actionType, data, title, desc, ){
            this.openModal = !this.openModal
            this.actionType = actionType
            this.modalProps = data
            this.modalTitle = title
            this.modalDesc = desc
        },
           
           handleFileUpload(item) {
                this.data.file = item.target.files[0];
            },
            removeItem: async function(item){
                await this.$store.dispatch('productionInvoiceDetail_remove', {"productionInvoiceDetailItem": item.id})
                if(this.$store.getters.productionStatus.success){
                    this.openModalEvent()
                    await this.$store.dispatch('productionInvoiceDetail_list', { productionInvoiceId: this.data.id});
                }
            }
       }
   }
</script>